/**
 * @file Index Page
 * @author Alwyn Tan
 */

import Page from '#/components/templates/Page'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const Index = () => {
  useEffect(() => {
    window.analytics.page()
    navigate('/me')
  }, [])

  return (
    <Page>
      <Container>
        <StaticImage
          src="../images/discoman.png"
          alt="discoman"
          height={200}
          width={200}
        />
      </Container>
    </Page>
  )
}

export default Index
